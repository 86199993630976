import { navigate } from "gatsby"
import React from "react"

const NotFound = () => {
  // if (typeof window !== undefined) {
  // window.location = "/de/"
  // }
  React.useEffect(() => {
    navigate("/de/")
  }, [])
  return true
}

export default NotFound
